import axiosPrivate from "../../../hooks/axiosPrivate";
import { useNavigate, useParams } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import styles from "./AddTicketReply.module.css";
import Loader from "../../../context/Loader/Loader";
import LoaderContext from "../../../context/Loader/LoaderContext";
import classes from "../TicketList/TicketList.module.css";
import { errorToast, successToast } from "../../../utils/Toaster";
import "react-datepicker/dist/react-datepicker.css";
import { BsCalendar } from "react-icons/bs";
import DatePicker from "react-datepicker";
import AlertContext from "../../../context/Alert/AlertContext";
const AddTicketReply = ({ setShowReplyComponent, ...props }) => {
  const { id } = useParams();
  const [editorContent, setEditorContent] = useState("");
  const navigate = useNavigate();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [projectEmployees, setProjectEmployees] = useState([]);
  // const [projectClients, setProjectClients] = useState([]);
  const [ticket, setTicket] = useState({});
  const [raiseByUserId, setRaiseByUserId] = useState("");
  const { loading, setIsLoading } = useContext(LoaderContext);

  const userData = JSON.parse(localStorage.getItem("userData")) || {};

  const [selectedButton, setSelectedButton] = useState(
    userData.role === "3" ? "external" : "internal"
  );
  const [selectedProjectId, setSelectedProjectId] = useState("");
  const [fileSizeError, setFileSizeError] = useState(false);
  const [imageFile, setImageFile] = useState();
  const { sendNotification } = useContext(AlertContext);

  const handleInternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("internal");
  };

  const handleCloseReplyComponent = () => {
    // Call setShowReplyComponent with false to hide the form
    setShowReplyComponent(false);
  };

  const handleExternalButtonClick = (e) => {
    e.preventDefault();
    setSelectedButton("external");
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setImageFile(file);
  };
  useEffect(() => {
    if (localStorage.getItem("userData")) {
      const userData = JSON.parse(localStorage.getItem("userData"));
      setRaiseByUserId(userData?.userId);
    }
  }, []);

  const handleEditorChange = (value) => {
    formik.setFieldValue("description", value);
    setEditorContent(value);
  };

  const fetchEmployeesByProjectId = async (project_id) => {
    try {
      const response = await axiosPrivate.get(
        `project/emp-by-project-id/${project_id}`
      );

      const employees = response?.data?.data || [];
      setProjectEmployees(employees);
      formik.setFieldValue("employee_user_id", []);
    } catch (error) {}

    // try {
    //   const response = await axiosPrivate.get(
    //     `project/client-by-project-id/${project_id}`
    //   );
    //   const clients = response?.data?.data || [];

    //   setProjectClients(clients);
    //   formik.setFieldValue("employee_user_id", []);
    // } catch (error) {}
  };

  useEffect(() => {
    axiosPrivate
      .post(`tickets-by-task/${id}`)
      .then((response) => {
        const fetchedTicket = response.data.data;
        setTicket(fetchedTicket[0]);
      })
      .catch((error) => {});

    // axiosPrivate
    //   .get("users")
    //   .then((response) => {
    //     const fetchedEmployeeOptions = response.data.data.map((employee) => ({
    //       value: employee.id,
    //       label: employee.name,
    //       role_name: employee.role_name,
    //     }));
    //     setEmployeeOptions(fetchedEmployeeOptions);
    //   })
    //   .catch((error) => {});
  }, []);

  useEffect(() => {
    fetchEmployeesByProjectId(ticket?.project_id);
  }, [ticket]);

  const validationRules1 = {
    status: Yup.string().required("Status is required"),
    priority: Yup.string().required("Priority is required"),
    actual_time: userData?.role !== "1" ? Yup.string().required("Actual time is required") : null,

    ticket_due_date: Yup.string().required("Date is required"),
    description: Yup.string().required("Description is required"),
  };

  const validationRules2 = {
    ...(userData?.role !== "3" && {
      status: Yup.string().required("Status is required"),
      priority: Yup.string().required("Priority is required"),
    }),
    description: Yup.string().required("Description is required"),
  };

  const formik = useFormik({
    initialValues: {
      team_name: "",
      client_user_id: "",
      employee_user_id: [],
      client_user_id: [],
      priority: "",
      status: "",
      description: "",
      project_id: "",
      task_id: ticket?.task_id,
      actual_time: "",
      document: "",
      ticket_due_date: null,
      assign_to: null,
    },
    validationSchema:
      selectedButton === "internal"
        ? Yup.object(validationRules1)
        : Yup.object(validationRules2),
    onSubmit: async (values, { setSubmitting, setFieldError }) => {
      const selectedEmployees = formik.values.employee_user_id;

      // const selectedClients = formik.values.client_user_id;
      const allSelectedUsers = [
        ...selectedEmployees.map((employee) => employee.value),
        // ...selectedClients.map((client) => client.value),
      ];
      const cleanedDescription = editorContent;
      const requestData = {
        project_id: ticket?.project_id,
        subject: ticket?.subject,
        description: cleanedDescription,
        internal_external: selectedButton === "internal" ? 0 : 1,
        priority: values?.priority,
        status: values?.status,
        actual_time: values?.actual_time,
        // user_id: selectedEmployees.includes("all")
        //   ? employeeOptions.map((employee) => employee.value)
        //   : selectedEmployees.map((employee) => employee.value),
        user_id: allSelectedUsers,
        raised_by_user_id: raiseByUserId,
        task_id: ticket?.task_id,
        thread_id: ticket.thread_id,
        document: values?.document,
        assign_to: values?.assign_to?.value,
      };

      const formData = new FormData();
      formData.append("project_id", requestData.project_id);
      formData.append("subject", requestData.subject);
      formData.append("description", requestData.description);
      formData.append("internal_external", requestData.internal_external);
      formData.append("priority", requestData.priority);
      formData.append("status", requestData.status);
      formData.append("actual_time", requestData.actual_time);
      formData.append("user_id", requestData.user_id);
      formData.append("raised_by_user_id", requestData.raised_by_user_id);
      formData.append("task_id", requestData.task_id);
      formData.append("thread_id", requestData.thread_id);
      formData.append("image", values?.document);
      formData.append("assign_to", requestData.assign_to || null);

      {
        selectedButton === "internal" &&
          formData.append("ticket_due_date", values?.ticket_due_date);
      }

      try {
        const response = await axiosPrivate
          .post("ticket/raise", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then((res) => {
            if (res?.status === 201) {
              successToast("Reply sent successfully");

              // Function to remove HTML tags
              const stripHtmlTags = (str) => {
                const doc = new DOMParser().parseFromString(str, "text/html");
                return doc.body.textContent || "";
              };

              const plainTextDescription = stripHtmlTags(
                requestData.description
              );

              if (values?.assign_to?.register_id) {
                sendNotification(
                  values?.assign_to?.register_id,
                  requestData?.subject,
                  plainTextDescription,
                  `/admin/project-ticket-list/view/${id}`,
                  values?.assign_to?.value,
                  1
                );
              }
              window.location.reload();
              navigate(`/admin/project-ticket-list/view/${id}`);
            }
          })
          .catch((error) => {
            console.log("Error", error);
          });
      } catch (error) {
        if (
          (error.response && error.response.status === 422) ||
          error.response.status === 500
        ) {
          for (let i = 0; i < error.response.data.errors.length; i++) {
            setFieldError(
              error.response.data.errors[i].param,
              error.response.data.errors[i].msg
            );
          }
        }
        errorToast(error?.response?.data?.errors);
      }
    },
  });

  const formatDate = (inputDateStr) => {
    if (!inputDateStr) {
      return null;
    }

    const parsedDate = new Date(inputDateStr);

    parsedDate.setHours(0, 0, 0, 0);

    const day = parsedDate.getDate().toString().padStart(2, "0");
    const month = (parsedDate.getMonth() + 1).toString().padStart(2, "0");
    const year = parsedDate.getFullYear();

    return `${year}-${month}-${day}`;
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <div>
          <form onSubmit={formik.handleSubmit}>
            <div className="card">
              <div className="card-body  ">
                <div className="btn-group">
                  {userData.role !== "3" ? (
                    <>
                      <button
                        onClick={handleInternalButtonClick}
                        className={`btn btn-outline-primary   ${
                          selectedButton === "internal" ? "active" : ""
                        }`}
                      >
                        Internal
                      </button>
                      <button
                        onClick={handleExternalButtonClick}
                        className={`btn btn-outline-primary  ${
                          selectedButton === "external" ? "active" : ""
                        }`}
                      >
                        External
                      </button>
                    </>
                  ) : null}
                </div>
                {selectedButton === "internal" && (
                  <>
                    <div className="row clearfix mt-3">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled selected>
                              Select Status *
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Ready For UAT">Ready For UAT</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          >
                            <option value="" disabled selected>
                              Select Priority *
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      {
                        userData?.role !== "1" && (
                          <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="actual_time"
                            name="actual_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.actual_time}
                            style={{
                              height: "40px",
                              maxHeight: "50px",
                              overflowY: "auto",
                            }}
                          >
                            <option value="" disabled>
                              Select Actual Time *
                            </option>
                            <option value="00">0Mins</option>
                            <option value="15">15Mins</option>
                            <option value="30">30Mins</option>
                            <option value="45">45Mins</option>
                            <option value="60">1Hrs</option>
                            <option value="75">1Hr 15Mins</option>
                            <option value="90">1Hr 30Mins</option>
                            <option value="105">1Hr 45Mins</option>
                            <option value="120">2Hrs</option>
                            <option value="135">2Hr 15Mins</option>
                            <option value="150">2Hr 30Mins</option>
                            <option value="165">2Hr 45Mins</option>
                            <option value="180">3Hrs</option>
                          </select>

                          {formik.touched.actual_time &&
                          formik.errors.actual_time ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.actual_time}
                            </div>
                          ) : null}
                        </div>
                      </div>
                        )
                      }

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group ">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            className="custom-style"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees
                                .filter(
                                  (item) => item.name !== userData?.userName
                                )
                                .map((employee) => ({
                                  value: employee.id,
                                  label: employee.name,
                                  role_name: employee.role_name,
                                  register_id: employee.register_id,
                                })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                // Set employee_user_id to all employees for the current project
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                    register_id: employee.register_id,
                                  }))
                                );
                              } else {
                                // Set employee_user_id to selected employees
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                    register_id: option.register_id,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            // required
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All "
                                : "Notify To"
                            }
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              multiValueLabel: (provided) => ({}),
                              multiValueRemove: (provided) => ({
                                ...provided,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <Select
                            id="assign_to"
                            name="assign_to"
                            className="custom-style"
                            isClearable
                            options={projectEmployees
                              .filter(
                                (item) => item.name !== userData?.userName
                              )
                              .map((employee) => ({
                                value: employee.id,
                                label: employee.name,
                                role_name: employee.role_name,
                                register_id: employee.register_id,
                              }))}
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(
                                "assign_to",
                                selectedOptions
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.assign_to}
                            placeholder="Re-assign"
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.assign_to &&
                            formik.errors.assign_to && (
                              <div className={styles.invalidDataError}>
                                {formik.errors.assign_to}
                              </div>
                            )}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <input
                            type="file"
                            id="document"
                            name="document"
                            className="form-control"
                            onChange={(e) =>
                              formik.setFieldValue(
                                "document",
                                e.target.files[0]
                              )
                            }
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <div className="input-group datepickerwidth">
                            <DatePicker
                              className="form-control "
                              id={`ticket_due_date`}
                              name={`ticket_due_date`}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `ticket_due_date`,
                                  date ? formatDate(date) : null
                                );
                              }}
                              onBlur={formik.handleBlur}
                              selected={
                                formik.values.ticket_due_date
                                  ? new Date(formik.values.ticket_due_date)
                                  : null
                              }
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Due Date (dd-mm-yyyy) *"
                              minDate={new Date()} // Disable previous dates
                            />

                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() =>
                                  document
                                    .getElementById(`ticket_due_date`)
                                    .click()
                                }
                              >
                                <BsCalendar />
                              </button>
                            </div>
                          </div>
                          {formik.touched.ticket_due_date &&
                          formik.errors.ticket_due_date ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.ticket_due_date}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {selectedButton === "external" && userData?.role !== "3" && (
                  <>
                    <div className="row clearfix mt-3">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled>
                              Select Status *
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Ready For UAT">Ready For UAT</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          >
                            <option value="" disabled selected>
                              Select Priority *
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group ">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            className="custom-style"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees
                                .filter(
                                  (item) => item.name !== userData?.userName
                                )
                                .map((employee) => ({
                                  value: employee.id,
                                  label: employee.name,
                                  role_name: employee.role_name,
                                  register_id: employee.register_id,
                                })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                    register_id: employee.register_id,
                                  }))
                                );
                              } else {
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                    register_id: option.register_id,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All "
                                : "Notify To"
                            }
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              multiValueLabel: (provided) => ({}),
                              multiValueRemove: (provided) => ({
                                ...provided,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group ">
                          <Select
                            id="assign_to"
                            name="assign_to"
                            className="custom-style"
                            isClearable
                            options={[
                              ...projectEmployees
                                .filter(
                                  (item) => item.name !== userData?.userName
                                )
                                .map((employee) => ({
                                  value: employee.id,
                                  label: employee.name,
                                  role_name: employee.role_name,
                                  register_id: employee.register_id,
                                })),
                            ]}
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(
                                "assign_to",
                                selectedOptions
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.assign_to}
                            // required
                            placeholder={"Re-assign"}
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              multiValueLabel: (provided) => ({}),
                              multiValueRemove: (provided) => ({
                                ...provided,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.assign_to &&
                          formik.errors.assign_to ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.assign_to}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <input
                            type="file"
                            id="document"
                            name="document"
                            className="form-control"
                            onChange={(e) =>
                              formik.setFieldValue(
                                "document",
                                e.target.files[0]
                              )
                            }
                            accept="image/*"
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                {selectedButton === "external" && userData?.role === "3" && (
                  <>
                    <div className="row clearfix">
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="status"
                            name="status"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.status}
                          >
                            <option value="" disabled>
                              Select Status
                            </option>
                            <option value="Pending">Pending</option>
                            <option value="In Progress">In Progress</option>
                            <option value="Ready For Q/A">Ready For Q/A</option>
                            <option value="Done">Done</option>
                            <option value="On Hold">On Hold</option>
                            <option value="Ready For UAT">Ready For UAT</option>
                          </select>
                          {formik.touched.status && formik.errors.status ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.status}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <select
                            className="form-control"
                            id="priority"
                            name="priority"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.priority}
                          >
                            <option value="" disabled selected>
                              Select Priority
                            </option>
                            <option value="4">Critical</option>
                            <option value="3">High</option>
                            <option value="2">Medium</option>
                            <option value="1">Low</option>
                          </select>

                          {formik.touched.priority && formik.errors.priority ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.priority}
                            </div>
                          ) : null}
                        </div>
                      </div>

                      <div className="col-md-6 col-lg-4">
                        <div className="form-group ">
                          <Select
                            id="employee_user_id"
                            name="employee_user_id"
                            className="custom-style"
                            isMulti
                            options={[
                              {
                                value: "all",
                                label: "Select All",
                                role_name: "Employee",
                              },
                              ...projectEmployees
                                .filter(
                                  (item) => item.name !== userData?.userName
                                )
                                .map((employee) => ({
                                  value: employee.id,
                                  label: employee.name,
                                  role_name: employee.role_name,
                                })),
                            ]}
                            onChange={(selectedOptions) => {
                              const allEmployeesOption = selectedOptions.find(
                                (option) =>
                                  option.value === "all" &&
                                  option.role_name === "Employee"
                              );

                              if (allEmployeesOption) {
                                // Set employee_user_id to all employees for the current project
                                formik.setFieldValue(
                                  "employee_user_id",
                                  projectEmployees.map((employee) => ({
                                    value: employee.id,
                                    label: employee.name,
                                    role_name: employee.role_name,
                                    register_id: employee.register_id,
                                  }))
                                );
                              } else {
                                // Set employee_user_id to selected employees
                                formik.setFieldValue(
                                  "employee_user_id",
                                  selectedOptions.map((option) => ({
                                    value: option.value,
                                    label: option.label,
                                    role_name: option.role_name,
                                    register_id: option.register_id,
                                  }))
                                );
                              }
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.employee_user_id}
                            // required
                            placeholder={
                              formik.values.employee_user_id &&
                              formik.values.employee_user_id.some(
                                (option) => option.value === "all"
                              )
                                ? "Select All "
                                : "Notify To"
                            }
                            styles={{
                              // menu: (provided) => ({
                              //   ...provided,
                              //   maxHeight: '100px',
                              //   overflowY: 'auto',
                              // }),
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              multiValueLabel: (provided) => ({}),
                              multiValueRemove: (provided) => ({
                                ...provided,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.employee_user_id &&
                          formik.errors.employee_user_id ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.employee_user_id}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group ">
                          <Select
                            id="assign_to"
                            name="assign_to"
                            className="custom-style"
                            isClearable
                            options={[
                              ...projectEmployees
                                .filter(
                                  (item) => item.name !== userData?.userName
                                )
                                .map((employee) => ({
                                  value: employee.id,
                                  label: employee.name,
                                  role_name: employee.role_name,
                                  register_id: employee.register_id,
                                })),
                            ]}
                            onChange={(selectedOptions) => {
                              formik.setFieldValue(
                                "assign_to",
                                selectedOptions
                              );
                            }}
                            onBlur={formik.handleBlur}
                            value={formik.values.assign_to}
                            // required
                            placeholder={"Re-assign"}
                            styles={{
                              multiValue: (provided) => ({
                                ...provided,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }),
                              multiValueLabel: (provided) => ({}),
                              multiValueRemove: (provided) => ({
                                ...provided,
                              }),
                              valueContainer: (provided) => ({
                                ...provided,
                                maxHeight: "25px",
                                overflowY: "auto",
                              }),
                            }}
                          />
                          {formik.touched.assign_to &&
                          formik.errors.assign_to ? (
                            <div className={styles.invalidDataError}>
                              {formik.errors.assign_to}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <input
                            type="file"
                            id="document"
                            name="document"
                            className="form-control"
                            onChange={(e) =>
                              formik.setFieldValue(
                                "document",
                                e.target.files[0]
                              )
                            }
                            accept="image/*"
                          />
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-4">
                        <div className="form-group">
                          <div className="input-group datepickerwidth">
                            <DatePicker
                              className="form-control "
                              id={`ticket_due_date`}
                              name={`ticket_due_date`}
                              onChange={(date) => {
                                formik.setFieldValue(
                                  `ticket_due_date`,
                                  date ? formatDate(date) : null
                                );
                              }}
                              onBlur={formik.handleBlur}
                              selected={
                                formik.values.ticket_due_date
                                  ? new Date(formik.values.ticket_due_date)
                                  : null
                              }
                              // disabled={formSubmitted}
                              dateFormat="dd-MM-yyyy"
                              placeholderText="Due Date (dd-mm-yyyy) *"
                              minDate={new Date()} // Disable previous dates
                            />

                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={() =>
                                  document
                                    .getElementById(`ticket_due_date`)
                                    .click()
                                }
                              >
                                <BsCalendar />
                              </button>
                            </div>
                          </div>
                          {formik.touched.ticket_due_date &&
                          formik.errors.ticket_due_date ? (
                            <div className={classes.invalidDataError}>
                              {formik.errors.ticket_due_date}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div>
                  <div className="form-group  ">
                    <ReactQuill
                      theme="snow"
                      value={editorContent}
                      required
                      onChange={handleEditorChange}
                    />
                    {formik.touched.description && formik.errors.description ? (
                      <div className={styles.invalidDataError}>
                        {formik.errors.description}
                      </div>
                    ) : null}
                  </div>

                  <div className="form-group">
                    <button type="submit" className="btn btn-primary">
                      Post Reply
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-primary ml-2"
                      onClick={handleCloseReplyComponent}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default AddTicketReply;
